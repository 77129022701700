.about-container {
    display: flex;
    padding: 20px;
    background-color: #f9f9f9; 
    border-bottom:  20px solid rgb(233 225 241)
    /* margin-top: 150px; */
  }
  
  .image-container {
    flex: 1;
  }
  
  .lodge-image {
    margin: 10px;
    width: 90%;
    /* animation: fadeIn 1s ease forwards; */
}
.rightbar {
  background: #213764;
  width: 84%;
  height: 2%;
}
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  
  .content-container {    
    flex: 1 1;
    padding: 0 20px;
    text-align: left;
    padding: 75px;
  }
  
  .content-container h2 {
    /* font-size: 24px; */
    margin-bottom: 10px;
  }
  
  .content-container p {
    font-size: 16px;
    line-height: 1.5;
  }
  @media screen and (max-width: 640px) {
  .content-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .content-container p {
    font-size: 14px;
    line-height: 1.5;
  }
    .about-container {
      display: inline-block;
      padding: 20px;
      background-color: #f9f9f9; 
      margin-top: 20px;
    }
    .content-container {
      flex: 1 1;
      padding: 0;
      text-align: left;
      /* padding: 75px; */
  }
  .content-div {
    
  }

  .book-now-in-about{
    text-align: center;
    margin-top: 10px;
  }

  .book-now{
    padding: 10px;
    font-size: 12px;
  }
  .lodge-image {
    margin: 4px;
    width: 100%;
}

  }
