.content-div{
  height:655px;
}
.image-detail{
  display: flex;
  align-items: center;
  justify-content: center;
}
.relative{
    position: relative;
  }
  .image-detail-lines{
    font-size: 20px;
  }
  .box {
    position: absolute;
    border: 2px solid rgb(255, 255, 255); /* Adjust border properties here */
    background-color: #f0f0f0;
    /*animation: fade-in-down 4000ms ease infinite;*/
  }
  
  .box-0 {
    width: 237px;
    height: 275px;
    top : 52px;
    left :137px;
    /* animation: bounce-in 5000ms ease infinite,fadeInAnimation ease 500ms;
    animation-fill-mode: forwards;
    opacity: 0; */
  }
  
  .box-1 {
    width: 237px;
    height: 223px;
    top : 376px;
    left : 138px;
    /* animation: bounce-in 5500ms ease infinite,fadeInAnimation ease 1000ms;
    animation-fill-mode: forwards;
    opacity: 0; */
  }
  
  .box-2 {
    width: 234px;
    height: 326px;
    top : 213px;
    left : 414px;
    /* animation: bounce-in 6000ms ease infinite,fadeInAnimation ease 1500ms;
    animation-fill-mode: forwards;
    opacity: 0; */
  }
  
  .bounce-in {
    animation: bounce-in 2 ease infinite;
  }
  
  .container-width {
    width: 12px;
    height: 248px;
    background-color: transparent;
    top: 238px;
    left: 84px;
    position: relative;
  }
  
  .bar-width {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #ffffff; /* Color of the bar */
    animation: increaseAndDecreaseWidth 6s linear infinite;
  }
  
  @keyframes increaseAndDecreaseWidth {
    0%, 100% { width: 0%; }
    50% { width: 100%; }
  }
  
  .container-height {
    width: 302px;
    height: 12px;
    background-color: transparent;
    top: -107px;
    left: 406px;
    position: relative;
  }
  
  .bar-height {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff; /* Color of the bar */
    animation: increaseAndDecreaseHeight 6s linear infinite;
  }

  .why-heading{

  }
  .photos-div{
    width: 40%;
  }
  .image-detail-content-div{
    width: 60%;
    margin-bottom: 42px;
  }
  .image-detail-heading{
    padding: 0px 0px 16px 0px;
    top: 0px;
    left: 1px;
    font-size: 35px;
    position: relative;
    font-family: 'PlayFair';
    color: #ff8200;
  }
  
  .line-spacing{
    padding: 1px 0px 6px 0px;
    /* color: white; */
    font-size: 21px;
  }
  
  @keyframes increaseAndDecreaseHeight {
    0%, 100% { height: 0%; }
    50% { height: 100%; }
  }
  
  @keyframes fadeInAnimation {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes bounce-in {
    0% {
      opacity: 0;
      transform: scale(.3);
    }
    50% {
      opacity: 1;
      transform: scale(1.05);
    }
    70% { transform: scale(.9); }
    100% { transform: scale(1); }
  }

@media screen and (max-width: 640px) {
  .photos-div {
    width: 95%;
    margin-top: 38px;
}
.image-detail {
  padding: 0;
  display: flex;
  flex-direction: column;
}
  .content-div{
    height: 655px;
  }

  .container-width {
    width: 4px;
    height: 202px;
    top: 127px;
    left: 18px;
    position: relative;
}

.container-height {
  width: 166px;
  height: 4px;
  top: -70px;
  left: 216px;
  position: relative;
}

  .box-0 {
    width: 143px;
    height: 191px;
    top: 42px;
    left: 42px;
}

  .box-1 {
    width: 143px;
    height: 136px;
    top: 258px;
    left: 42px;
}

.box-2 {
  width: 145px;
  height: 197px;
  top: 158px;
  left: 219px;
}

  .image-detail-content-div {
     /* padding: 0px 0px 0px 0px; */
    /* top: 430px; */
    /* position: absol  ute; */
    width: 83%;
    font-size: 21px;
}

.image-detail-lines{
    /* top: 35px; */
    position: relative;
    /* left: 42px; */
}

.image-detail-heading {
  padding: 1px 0px 0px 0px;
  top: 13px;
  /* left: 45px; */
  font-size: 19px;
}

  .line-spacing{
    font-size: 13px;
    left: 12px;
  }
}