.footer {
  border-top: 2px solid #767070;
  padding: 30px 0;
  opacity: 0;
  border-bottom: 3px solid white;
  text-align: left;
  box-shadow: -1px -20px 0px 0px #bb9dcd29, 0 0 0 5px #ecdbff;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 10%;
}

.footer-section {
  flex: 1 1 300px;
  margin-bottom: 20px;
}

.footer-section h3 {
  margin-bottom: 10px;
}

.footer-bottom {
  bottom: -35px;
  position: relative;
  text-align: center;
  padding-top: 30px;
  background: #333;
  color: #fff;
  height: 46px;
}

.contact-details{
  margin-top: 30px;
}

.whats-up-icon-footer{
  width: 20px;
  height: 20px;
  /* padding-top: 5px; */
  padding-right: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); 
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer-booking{
  text-decoration: blink;
}

.just-dial-footer{
  padding: 5px 10px;
  background: #257fd3;
  color: white;
  /* margin-top: 23px; */
  border-radius: 2px;
}
