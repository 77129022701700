.card-list {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap between cards */
    /* width: 50%; */
    padding: 20px;
  }
  
  

  /* ---------------------------------- */

  .card-wrapper-outer {
    width: 800px;
    margin: 10px 0;
    display: flex;
    padding: 10px 2px;
    align-items: center;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* justify-content: space-between; */
}
.card-wrapper-left-theme{
  justify-content: flex-start;
}
.card-wrapper-right-theme{
  justify-content: flex-end;
}
.content-wrapper-right-theme{
  text-align: right;
}
.content-wrapper-left-theme{
  text-align: left;
}

  .service-headings{
    height: 13%;
  }

  .service-card{
    height: 87%;
    width: fit-content;
    /* display: block; */
    margin: auto;
  }
  .icon-internal-wrapper {
    box-shadow: -5px 4px 10px #d4c8c8;
    position: relative;
    right: 8px;
    width: 10%;
    padding: 17px 5px;
    /* border-top-right-radius: 42px; */
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    /* border-radius: 0 87% 0 0; */
    justify-content: space-around;
    align-items: center;
    /* background: orange; */
}

.icon-internal-wrapper-right {
  box-shadow: -5px 4px 10px #d4c8c8;
  position: relative;
  left: 13px;
  width: 10%;
  padding: 17px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 40px;
  justify-content: space-around;
  align-items: center;
  /* background: orange; */
}

  .content-wrapper {
    font-family: 'Poppins';
    /* text-align: left; */
    padding: 5px 17px;
}

  .content-header{
    padding: 5px 0;
    font-weight: bold;
  }
  .content-internal{
    font-size: 13px;
  }

  .count-internal{
    color: white;
    font-weight: bold;
  }
  .count-internal-img{
    padding: 6px 5px;
    background: white;
    border-radius: 50%;
    box-shadow: -10px -10px 15px rgba(255,255,255,0.5),
            10px 10px 15px rgba(70,70,70,0.12);
  }

  .orange-t{

    .icon-internal-wrapper-right{
      background: orange;
    }

    .icon-internal-wrapper{
      background: #eddd61cf;
    }
    .content-header{
      color: orange;
    }
  }

  .orange-t{

    .icon-internal-wrapper-right{
      background: orange;
    }

    .icon-internal-wrapper{
      background: orange;
    }
    .content-header{
      color: orange;
    }
  }

  .red-t{

    .icon-internal-wrapper-right{
      background: red;
    }

    .icon-internal-wrapper{
      background: red;
    }
    .content-header{
      color: red;
    }
  }

  .purple-t{

    .icon-internal-wrapper-right{
      background: purple;
    }

    .icon-internal-wrapper{
      background: purple;
    }
    .content-header{
      color: purple;
    }
  }

  .blue-t{

    .icon-internal-wrapper-right{
      background: blue;
    }

    .icon-internal-wrapper{
      background: blue;
    }
    .content-header{
      color: blue;
    }
  }
  .green-t{

    .icon-internal-wrapper-right{
      background: green;
    }

    .icon-internal-wrapper{
      background: green;
    }
    .content-header{
      color: green;
    }
  }
  @media screen and (max-width: 640px)  {
    .card-wrapper-outer {
      width: 315px;
    }  
  }
  