.highlights-comp-wrapper {
  /* background: aliceblue; */
  margin-top: 30px;
  padding: 20px;
  box-shadow: 0px 0px 5px #dfd6d6;
  border-radius: 5px;
}
.highlights-comp-header {
  width: -moz-fit-content;
  position: absolute;
  left: 19px;
  top: 166px;
  width: fit-content;
  background: #dbbedf36;
  padding: 10px 27px 10px 8px;
  color: #000000;
  border-bottom-right-radius: 20px;
}
.highlights-comp-content-wrapper{
    margin: 20px;
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
}
.highlights-comp-content {
  width: 30%;
  padding: 26px 87px;
}
.highlights-comp-inner-hdr{
    color: blueviolet;
}
.highlights-comp-second-hdr{
    color: #8a2be2e6;
}
.highlights-comp-main-content{
    font-size: 12px;
    color: gray;
    padding-top: 10px;
}

.image-content-container-abt {
    display: flex;
    align-items: center;
  }
  
  .image-container-abt {
    /* flex: 1; */
    width: 45%;
  }
  
  .content-abt-left {
    /* flex: 2; */
    width: 50%;
    padding-left: 55px;
  }
  .content-abt-right {
    /* flex: 2; */
    width: 50%;
    padding-right: 55px;
  }
  .content-image-border {
    width: 100%;
    /* max-width: 300px; */
    /* height: auto; */
    padding: 10px;
    /* border-radius: 8px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }

  .content-image-abt {
    width: 100%;
    /* max-width: 300px; */
    /* height: auto; */
    padding: 10px;
    /* border-radius: 8px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */

  }
  
  .content-abt-right h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .content-abt-right p {
    font-size: 16px;
    line-height: 1.5;
    color: gray;
  }
  .content-abt-left h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .content-abt-left p {
    font-size: 16px;
    line-height: 1.5;
    color: gray;
  }
  
  /* Animation */
  .image-content-container-abt {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease forwards;
    padding: 24px 30px;
    /* opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
    animation: fadeInScale 0.5s ease forwards; */
  }

  
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.highlights-comp-other-facilities-wrapper{
  /* width: 30%; */
  display: flex;
}
.highlights-comp-other-facilities-flex-wrapper{
  width: 30%;
  padding: 57px;
}

.highlights-comp-other-facilities-content{
  font-size: 13px;
  padding-top: 5px;
}

.highlights-comp-other-facilities-image{
  border-bottom: 3px solid blue;
  padding-bottom: 6px;
}
  
@media screen and (max-width: 640px)  {
  
.highlights-comp-content{
  width: 30%;
  padding: 0;
}
.highlights-comp-content-wrapper {
  margin-top: 30px;
}
.image-content-container-abt {
  display: block;
  align-items: center;
}.image-container-abt {
  /* flex: 1; */
  width: 90%;
}

.content-abt-left  p {
  font-size: 14px;
}
.content-abt-right p {
  font-size: 14px;
}
.content-abt-left {
  /* flex: 2; */
  width: 90%;
  padding-left: 0;
}
.content-abt-right {
  /* flex: 2; */
  width: 90%;
  padding-right: 0;
}
.highlights-comp-other-facilities-flex-wrapper {
  width: 30%;
  padding: 1%;
}
.highlights-comp-other-facilities-content {
  font-size: 10px;
  padding-top: 5px;
}
.highlights-comp-second-hdr {
  font-size: 7px;
  color: #8a2be2e6;
}
.highlights-comp-main-content {
  font-size: 10px;
}
.highlights-comp-inner-hdr {
  font-size: 15px;
  color: blueviolet;
}
.highlights-comp-wrapper {
  /* background: aliceblue; */
  padding: 40px 18px 0 18px;
  box-shadow: 0px 0px 5px #dfd6d6;
  border-radius: 5px;
}
.highlights-comp-header {
  font-size: 13px;
}

}