.App {
  text-align: center;
}
header {
  background-color: #333;
  color: #fff;
  padding: 10px 20px; /* Adjust padding as needed */
  position: fixed;
  top: 0;
  left:-30px;
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center; /* Center content vertically */
  z-index: 1000; /* Ensure it's above other content */
}
.container-btn{
    position: fixed;
    bottom: 30px;
    right: 30px;
  } 
  .tel-info {
    position: relative;
    transition: transform 0.3s ease, border-radius 0.3s ease; /* Smooth transition for transform and border-radius */
  }
  .fixed-text{
    font-size: larger;
    font-weight: bold;
  }
  .tel-info::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* Adjust the color and opacity of the overlay */
    z-index: 1; /* Make sure the overlay is above the image */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition for opacity change */
  }
  
  .tel-info:hover::before {
    opacity: 1; /* Show the overlay when hovered */
    animation: contrast 1s linear infinite; /* Add animation */
  }
  
  .tel-info:hover {
    border-radius: 20%;
    transform: scale(1.05);
    box-shadow: 0px 0px 0px 2px #d4e9eb;
}
  
  /* Animation for the contrast effect */
  @keyframes contrast {
    0% {
      filter: contrast(100%);
      transform: translateX(0);
    }
    50% {
      filter: contrast(200%);
      transform: translateX(10px);
    }
    100% {
      filter: contrast(100%);
      transform: translateX(0);
    }
  }
  
  .button-btm1 {
    width: 60px;
    margin: 8px;
    animation: wave 3s infinite alternate;
  }
  .button-btm {
    width: 60px;
    margin: 8px;
    animation: wave 2s infinite alternate;
  }
  
  .button-btm1:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  .button-btm:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  @keyframes wave {
    0%, 10% , 18% , 90%, 100% {
      border-radius: 30px;
      border-bottom: 11px solid rgba(212, 213, 252, 0);
    }
    12%{
      border-bottom: 4px solid rgb(35 93 172);
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    }
    50% {
      transform: scale(1.1);
      /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
    }
    100% {
      transform: scale(1);
      /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    }
  }
  @keyframes blink {
    0%, 10% , 18% , 90%, 100% {
      transform: scaleY(1);
    }
    12%{
      transform: scaleY(0);
    }
  }
  .blink1 {
    /* Other styles... */
    animation: blink 5s infinite alternate; /* Apply animation */
  }
  .blink2 {
    /* Other styles... */
    animation: blink 3s infinite alternate; /* Apply animation */
  }
  .blink3 {
    /* Other styles... */
    animation: blink 4s infinite alternate; /* Apply animation */
  }
  .container-btn {
    /* Other styles... */
    /* animation: pulse 2s infinite alternate; Apply animation */
  }

  
nav {
  margin-left: auto; /* Pushes the navigation links to the right */
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  margin-left: 32px;
}

nav ul li a:hover {
  text-decoration: underline;
}
.lander-image{
  margin: 10px;
  width: 30%;
  height: auto;
  margin-bottom: 10px;
  animation: fadeIn 1s ease forwards;
}
.display-flex{
  display: flex;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
}
.display-flex-center{
  display: flex;
  align-items: center;
}
.display-justify-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.fadeIn{
  animation: fadeIn 1s ease forwards;
}
.ml120{
  margin-left: 120px;
}
.ml{
  margin-left: 20px;
}
.mt{
  margin-top: 20px;
}
.mt60{
  margin-top: 60px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.smoke-bg{
  background-color: fafafb;
}
.header-text{
 color:#213764;
 font-family: 'Poppins';
 font-size: 35px;
}
.content-text{
  color: #414775;
  font-family: Poppins;
}
.content-text-small {
  color: #414775;
  font-family: Poppins;
  font-size: small;
}

.content-text-white{
  color: #ffffff;
  font-family: Poppins;
}
.tel-info {
  width: 175px;
  margin: 20px;
}
.home {
  font-family: monospace;
  height: 100vh;
  border-bottom: 30px solid rgb(92 20 160 / 11%);

  .hotel-info-main {
    height: 50%;
    width: 80%;
    position: absolute;
    padding-top: 5%;
    left: 50%;
    transform: translateX(-50%);
    /* top: 60vh; */
    font-family: 'Poppins';
    .hotel-name {
      font-family: 'Poppins';
      font-weight: bold;
      font-size: 25px;
    }

    .hotel-address {
      font-size: 10px;
      font-family: Poppins;
      color: #838eaa;
    }
  }
}

.center-content {
  font-family: Poppins;
  font-size: 23px;
  color: #003c7b;
  padding: 27px;
}

.center-box {
  padding-top:10% ;
  font-family: 'Poppins';
}
.background-image {
  background-image: url('./Murugar.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh; /* Adjust height as needed */
}
.slideshow {
  margin: 0 auto;
  overflow: hidden;
  /* max-width: 300px; */
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  height: 100%;
  width: 98%;
  border-radius: 5px;
  margin: 3px;
  /* box-shadow: 0px 9px 8px 4px #e0c1ff66; */
}

/* Buttons */
.img-slider{
  max-width: 350px;
}
.slideshowDots {
  text-align: center;
}
slideshowDotWithImg {
  display: inline-block;
  height: 50px;
  width: 47px;
  border-radius: 15%;
  cursor: pointer;
  margin: 15px 7px 0px;
}
.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}
         
.whatsApp{
  /* background-image: url('./WhatsAppButtonGreenSmall.png'); */
}   

.button {
  background-image: linear-gradient(to right, #4776E6 0%, #8E54E9 51%, #4776E6 100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: inline-block;
  width: 168px;
}

.button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


/* .button {
  display: inline-block;
  padding: 6px 14px;
  background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%);
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  margin: 5px
} */
.hotel-rooms{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.hotel-rooms-img {
  display: none;
}

.hotel-rooms-card{
  border-bottom: 20px solid rgb(233 225 241);
}

.guest-love {
  border-bottom: 20px solid rgb(233 225 241);
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guest-icon{
  width: 176px;
}

.hotel-rooms-content{
  align-content: center;
  margin: 10%;
}
.bg-grad {
    /* background: linear-gradient(138deg, rgba(0,224,255,1) 0%, rgba(0,224,255,1) 2%, rgba(43,128,255,1) 55%, rgba(0,163,255,1) 100%); */
    background: linear-gradient(138deg, rgb(92 20 160) 0%, rgb(67 45 113) 2%, rgb(112 29 106 / 88%) 55%, rgb(40 4 55) 100%);
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    /* box-shadow: 0 0 20px #eee; */
    border-radius: 10px;
    display: block;
}

.bg-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
       

.btn-grad {
  /* background-image: linear-gradient(#6fa9ff, #b7fffe);  margin: 10px; */
  /* padding: 15px 45px; */
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  /* color: white; */
  /* box-shadow: 0 0 20px #eee; */
  /* border-radius: 10px; */
  display: block;
}
/* Square run */
@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

*{
    margin: 0px;
    padding: 0px;
}

body{
    font-family: 'Exo', sans-serif;
}


.context {
    width: 100%;
    position: absolute;
    top:50vh;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}




.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #c7cafd;
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 10px;
    height: 10px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 15px;
    height: 15px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 30px;
    height: 30px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 40px;
    height: 40px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 50px;
    height: 50px;
    animation-delay: 0s;
    animation-duration: 11s;
}

.purple{
  background: #e3ebff69;
  /* background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
}
.dark-grey{
  background-color: #21252d;
}
.smokeClr{
  background-color: #e6e6ee;
  height: 945px;
}
@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
/* end */
/* Spinner */
#html-spinner{
    width: 125%;
    height: 91%;
    border: 22px solid #79a7fc82;
    /* border-top: 20px solid white; */
    border-radius: 18%;
}

#html-spinner, #svg-spinner{
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1.2s;
  -webkit-animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  
  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate; 
  -moz-animation-duration: 1.2s; 
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  
  transition-property: transform;
  animation-name: rotate; 
  animation-duration:90s; 
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}

@-moz-keyframes rotate {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(360deg);}
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}


/* Rest of page style*/


#container{
  width:90%;
  max-width:700px;
  margin:1em auto;
  position:relative;
}

/* spinner positioning */

#html-spinner, #svg-spinner{
  position:absolute;
  top:80px;
  margin-left:-29px;
}

#html-spinner{
  left:25%; 
}

#svg-spinner{
  left:75%; 
}

#html-para, #svg-para{
  position:absolute;
  top:100px;
  width:40%;
  padding:5%;
  text-align:center;
}

#svg-para{
  left:50%;
}
.slideshowContainer{
    display: flex;
  .slide-img-show{
    width: 50%;
    box-shadow: 6px 10px 0px 5px white;
    /* border-radius: 5px; */
  }
  .slideMainContent{
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
    /* box-shadow: 13px 16px 0 4px; */
    /* border-radius: 5px; */
    width: 550px;
  }
  
  .mainContent {
    padding-left: 50px;
    font-size: 21px;
  }
}
@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Banner style ends */
.logo-image{
  height: 120px;
}

.amman-image-tag{
  height: 257px;
  width: 280px;
  border-radius: 50%;
}

.tamil-slogam{
  padding-left: 20px;
    text-align: left;
}

.thirukural{
  display: flex;
    align-items: center;
    justify-content: center;
  text-align: center;
  /* position: relative;
  left: 31%; */
  color: #213764;
  font-weight: bold;
  font-size: 18px;
  padding: 139px 8px;
  border-bottom: 30px solid #464662;
}
.thirukural-line{
  /* color: white; */
}

.thiruvalluvar{
  margin-left: 273px;
  /* color: white; */
  font-size: 14px;

}

/* Banner styles starts */

.banner {
  margin-top: 30px;
  background-color: #f0f0f0; /* Example background color */
  padding: 20px;
}

.location-header{
  font-size: 20px;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-text {
  flex: 1;
}

.banner-image {
  flex: 1;
  text-align: right;
}

.banner-image img {
  max-width: 100%;
  height: auto;
}

.highlight-temple{
  color: blue;
  font-size: 25px;
}
.header-btn-mob{
  display: none;
}
.mobview-top-icon {
  width: 30px;
  margin-right: 10px;
}
.menu-icon-desk{
  width: 13px;
}
.color-invert{
  -webkit-filter: invert(100%);
}
.text-highlight-green {
  border-bottom: #0cff00 solid 3px;
}
.text-highlight-blue {
  border-bottom: #b6ddff solid 3px;
}
.menu {
  top: 55px;
  position: fixed;
  background: #333333;
  width: 90px;
  font-family: 'Poppins';
  padding: 10px;
  flex-direction: column;
  right: 0;
}
.close{
  display: none;
}
.common-header-name{
    font-size: xxx-large;
    box-shadow: 0px 6px 4px 0px #f0f0f0;
    width: 100%;
    padding: 0px 21px 6px 20px;
}

@media screen and (max-width: 640px)  {
  header {
    height: 50px;
  }
  .common-header-name {
    font-size: xxx-large;
    box-shadow: 0px 6px 4px 0px #f0f0f0;
    width: 100%;
    padding: 0;
}
.guest-icon{
  width: 80px;
}
  .fixed-text{
    font-size: small;
    font-weight: bold;
  }
  .thirukural {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: white; */
    font-weight: bold;
    font-size: 15px;
    padding: 32px 8px;
}
.header-btn-mob{
  display: contents;
}
.header-btn-mob-icon{
  width: 40%;
}
nav ul li {
  margin: 0;
}
.header-btn{
  display: none;
}
.lander-image{
  display: none;
}
.tel-info {
  width: 170px;
  margin: 1px;
}
.amman-image-tag {
  height: 175px;
  width: 169px;
  border-radius: 50%;
}

.tamil-slogam {
  padding-left: 20px;
  text-align: left;
  font-size: 10px;
}
.thiruvalluvar {
  margin-left: 54px;
  /* color: white; */
  font-size: 9px;
}
.banner-content {
  display: contents;
  align-items: center;
  justify-content: space-between;
}
.location-header {
  font-size: 24px;
}

.highlight-temple {
  color: blue;
  font-size: 20px;
}

.slide {
  display: inline-block;
  height: 100%;
  width: 98%;
  border-radius: 5px;
  margin: 3px;
  /* box-shadow: 0px 9px 8px 4px #e0c1ff66; */
}

.hotel-rooms-img {
  display: block;
  height: 60%;
  width: 100%;
  padding-top: 30%;
}
  .home {
  
      .hotel-info-main {
        padding-top: 25%;
        .hotel-name {
          font-size:15px;
          /* Adjust font size for mobile */
        }
  
        .hotel-address {
          font-size: 10px;
          color: #868686;
        }
      }
    }
    .center-content{
      font-size: 14px;
      width: 80%;
    }
  .button {
    background-image: linear-gradient(to right, #4776E6 0%, #8E54E9 51%, #4776E6 100%);
    margin: 8px;
    padding: 11px 13px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 5px;
    display: inline-block;
    width: 95px;
    font-size: 9px;
}
.center-box {
  height: 45%;
  /* width: 90%; */
}

.slideshowContainer {
  display: contents;
  .slideMainContent {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
    border-radius: 5px;
    padding-top: 0px;
    width: fit-content;
  }
  .mainContent {
    padding-left: 0;
    font-size: 10px;
  }

}
}

.about-route-wrapper{
  padding: 0 20px;
}
.logo-image{
  height: 120px;
}


