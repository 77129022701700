.facility-container {
  /* background-color: #f9f9f9; */
  padding: 20px;
  /* border-radius: 8px; */
  
}

.facility-heading {
  /* color: #333; */
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.facility-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 23px;
}

.facility-column {
  flex: 1;
  margin-right: 20px;
}

.facility-item {
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  box-shadow: -12px 0px 19px 4px #cedeff87
}

.facility-item-icon {
  font-size: 20px;
  margin-right: 10px;
}

.facility-item-text {
  font-size: 16px;
}

.facility-item .icon {
  display: inline-block;
  margin-right: 10px;
  animation: bounce 1s ease infinite; /* Example animation */
}

.caption-facility{
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 15px;
  padding: 24px 0px;

}
.hotel-header{
  background: linear-gradient(22deg, #d546fc 0%, #8310c9 100%);
  padding: 20px;
  /* width: 40%; */
  color: aliceblue;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.facilities-inline-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #f9f9f9;
  /* border-bottom: 1px solid #a9a9a973; */
  margin-bottom: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.facility-inline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-header {
  margin: 0;
  margin: 0;
  font-weight: 400;
  color: blueviolet;
}

.facility-header{
  text-align: center;
  padding-bottom: 25px;
  font-weight: bolder;
  color: #464662;

}

.unicode-icon {
  font-size: 27px;
  border: 1px solid #eedd44;
  border-radius: 50%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}


/* -------------------------- */

.horizontal-container {
  display: flex;
  padding: 60px;
  background: #f9f3ff;
  margin-bottom: 55px;
  border-bottom:2px solid #8a2be270;
}

.horizontal-div {
  flex: 1;
  margin: 0 10px; /* Adjust margin as needed */
  text-align: center;
}

.facility-top-image {
  width: 42px;
  /* height: 33px; */
}

.facility-top-header {
  margin-top: 2px;
  font-size: 16px;
  color: #39145b;
}

.facility-top-content {
  margin-top: 10px;
  font-size: 11px;
}

.facility-bottom-image{
  width: 26px;
  height: 26px;
  padding: 3px 5px;
  margin-right: 7px;
}
.facility-header-caption{
  font-size: 12px;
  font-style: italic;
  margin-bottom: 25px;
}
.facility-new-left{
  width: 40%;
  padding: 20px 40px;
}
.facility-new-wrapper{
  display: flex;

}

/* ----------- stylings version 2-------------- */
.facitity-top-hdr-wrapper{
  /* padding: 0 431px;
  background: aliceblue; */
}

.facitity-top-hdr-inter-wrapper{
  margin-bottom: 21px;
}

.facitity-top-hdr-cont{
  font-size: 13px;
}


.facility-new-welcome{
  font-size: 12px;
}
.facility-new-hdr-cont{
  padding-bottom: 10px;
}
.facility-new-cont{
  color: gray;
}
.facility-new-enquiry{
  padding: 9px 20px;
  margin-top: 20px;
  /* margin: auto; */
  background: blue;
  color: white;
  border-radius: 9px;
}
.facility-new-why-us-content-wrapper{
  display: flex;
  flex-wrap: wrap;
}
.facility-new-why-us-content-120{
  padding-left: 120px;
  width: 32%;
  text-align: center;
}
.facility-new-why-us-content{
  width: 32%;
  text-align: center;
}

.facility-new-right{
  padding: 10px;
  width: 50%;
}
.facility-new-why-us-content{
  padding: 10px 20px;
}
.facility-new-icon-content {
  font-weight: bold;
  font-size: 13px;
}
/* .facitity-top-hdr{
  font-size: 24px;
  margin-bottom: 10px;
} */





@media screen and (max-width: 640px)  {
.facility-list{
  font-size: 10px;
}
.facility-item {
  margin-bottom: 20px;
  padding: 10px;
  padding-bottom: 13px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  /* box-shadow: 1px 1px 6px 1px #b0c0e0; */
  min-height: 57px;
}
.facility-top-header {
  margin-top: 5px;
  font-size: 10px;
}
.facility-top-content {
  margin-top: 10px;
  font-size: 10px;
}
.facility-new-left {
  width: 90%;
  padding: 16px;
}
.facility-new-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
.facility-new-right {
  padding: 0;
  width: 90%;
}
.facility-new-why-us-content-120{
  padding-left: 20px;
}
.facility-new-icon-content {
  font-weight: bold;
  font-size: 10px;
}.facility-new-why-us-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.horizontal-container {
  padding: 28px 0 30px;
}
}


