.contact-location-wrapper{
    padding: 17px 29px;
}
.contact-location-hotel-name-wrpper{
    display: flex;
    justify-content: space-between;
    /* padding: 30px 0; */
}

.contact-location-name-container {
    width: 40%;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* font-size: 63px; */
}

.contact-location-sub-header{
    font-size: 20px;
}
.contact-location-header{
    font-size: 35px;
    font-weight: 500;
    color: #3583eb;
}
.contact-location-content-location-cnt{
    width: 60%;
    font-size: 14px;
    color: gray;
}
.contact-location-content{
    width: 60%;
    font-size: 15px;
    padding: 13px 0;
    color: gray;
}
.contact-location-map-embed{
    padding: 20px;
    background: aliceblue;

    iframe {
        width: 100%;
        height: 300px;
    }
}

.contact-location-contact-flex{
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

.conntact-location-img{
    width: 23px;
    height: 23px;
}

.contact-location-contact-icon{
    display: flex;
    padding: 13px 0;
    font-size: 18px;
    align-items: center;
}

.contact-location-contact-title{
    /* padding-left: 13px; */
}
.content-location-cnt{
    font-size: 12px;
}


@media screen and (max-width: 640px)  {
    .contact-location-content-location-cnt {
        width: 90%;
        font-size: 14px;
        color: gray;
    }
    .contact-location-hotel-name-wrpper {
        display: block;
        /* justify-content: space-between; */
        padding: 30px 0;
    }
    .contact-location-name-container {
        width: 90%;
        padding: 10px 5px;
    }
    .contact-location-header {
        font-size: 16px;
        font-weight: bold;
        color: #3583eb;
    }
    .contact-location-contact-flex {
        display: flex;
        padding: 10px 0;
        flex-direction: column;
    }
    
}